import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space article-text">
      <Row>
        <Col>
          <h2 className="text-blue">
            {t('StepByStepInstructionsForBuildingQualitySystemArticleTitle1')}
          </h2>
          <p className="pb-4">
            {t('StepByStepInstructionsForBuildingQualitySystemArticlePara1')}
          </p>
          <h2 className="text-blue">
            {t('StepByStepInstructionsForBuildingQualitySystemArticleTitle2')}
          </h2>
          <p className="pb-4">
            {t('StepByStepInstructionsForBuildingQualitySystemArticlePara2')}
          </p>
          <h2 className="text-blue">
            {t('StepByStepInstructionsForBuildingQualitySystemArticleTitle3')}
          </h2>
          <p className="pb-4">
            {t('StepByStepInstructionsForBuildingQualitySystemArticlePara3')}
          </p>
          <h2 className="text-blue">
            {t('StepByStepInstructionsForBuildingQualitySystemArticleTitle4')}
          </h2>
          <p className="pb-4">
            {t('StepByStepInstructionsForBuildingQualitySystemArticlePara4')}
          </p>
          <h2 className="text-blue">
            {t('StepByStepInstructionsForBuildingQualitySystemArticleTitle5')}
          </h2>
          <p className="pb-4">
            {t('StepByStepInstructionsForBuildingQualitySystemArticlePara5')}
          </p>
          <h2 className="text-blue">
            {t('StepByStepInstructionsForBuildingQualitySystemArticleTitle6')}
          </h2>
          <p className="pb-4">
            {t('StepByStepInstructionsForBuildingQualitySystemArticlePara6')}
          </p>
          <h2 className="text-blue">
            {t('StepByStepInstructionsForBuildingQualitySystemArticleTitle7')}
          </h2>
          <p className="pb-4">
            {t('StepByStepInstructionsForBuildingQualitySystemArticlePara7')}
          </p>
          <h2 className="text-blue">
            {t('StepByStepInstructionsForBuildingQualitySystemArticleTitle8')}
          </h2>
          <p className="pb-4">
            {t('StepByStepInstructionsForBuildingQualitySystemArticlePara8')}
          </p>
          <h2 className="text-blue">
            {t('StepByStepInstructionsForBuildingQualitySystemArticleTitle9')}
          </h2>
          <p className="pb-4">
            {t('StepByStepInstructionsForBuildingQualitySystemArticlePara9')}
          </p>
          <h2 className="text-blue">
            {t('StepByStepInstructionsForBuildingQualitySystemArticleTitle10')}
          </h2>
          <p className="pb-4">
            {t('StepByStepInstructionsForBuildingQualitySystemArticlePara10')}
          </p>
          <h2 className="text-blue">
            {t('StepByStepInstructionsForBuildingQualitySystemArticleTitle11')}
          </h2>
          <p className="pb-4">
            {t('StepByStepInstructionsForBuildingQualitySystemArticlePara11')}
          </p>
          <h2 className="text-blue">
            {t('StepByStepInstructionsForBuildingQualitySystemArticleTitle12')}
          </h2>
          <p className="pb-4">
            {t('StepByStepInstructionsForBuildingQualitySystemArticlePara12')}
          </p>
          <p className="pb-4">
            {t('StepByStepInstructionsForBuildingQualitySystemArticlePara13')}
          </p>
        </Col>
      </Row>
    </Container>
  );
};
